import React from "react";
import { graphql } from "gatsby";
import { TwoColumnRte } from "./TwoColumnRte";
import {
  KontentComponentSettings,
  KontentRichText,
  KontentMultiChoice,
  MarginPaddingSizes,
  KontentBrandColor,
} from "../../../types/index";
import { getMultiChoice } from "../../../lib/multichoice";
import { getColor } from "../../../lib/color";

interface Props extends KontentComponentSettings {
  first_column: KontentRichText;
  second_column: KontentRichText;
  vertical_margins__margin_bottom: KontentMultiChoice<MarginPaddingSizes>;
  vertical_margins__margin_top: KontentMultiChoice<MarginPaddingSizes>;
  vertical_padding__padding_bottom: KontentMultiChoice<MarginPaddingSizes>;
  vertical_padding__padding_top: KontentMultiChoice<MarginPaddingSizes>;
  background_color: { value: KontentBrandColor[] };
}

const TwoColumnRteContainer: React.FC<Props> = (props) => {
  const margin = {
    top: getMultiChoice(props.vertical_margins__margin_top),
    bottom: getMultiChoice(props.vertical_margins__margin_bottom),
  };
  const padding = {
    top: getMultiChoice(props.vertical_padding__padding_top),
    bottom: getMultiChoice(props.vertical_padding__padding_bottom),
  };
  const backgroundColor = getColor(props.background_color);

  return (
    <TwoColumnRte
      id={props.id}
      anchor={props.component_settings__anchor_name?.value}
      hideOnMobile={
        getMultiChoice(props.component_settings__hide_on_mobile) === "yes"
      }
      firstColumn={props.first_column}
      secondColumn={props.second_column}
      margin={margin}
      padding={padding}
      background={backgroundColor}
    />
  );
};

export default TwoColumnRteContainer;

export const fragmentTwoColumnRteContainer = graphql`
  fragment TwoColumnRte on kontent_item_component___two_column_rte {
    system {
      id
      codename
    }
    internal {
      type
    }
    elements {
      component_settings__anchor_name {
        value
      }
      component_settings__hide_on_mobile {
        value {
          codename
        }
      }
      vertical_margins__margin_bottom {
        value {
          codename
        }
      }
      vertical_margins__margin_top {
        value {
          codename
        }
      }
      vertical_padding__padding_bottom {
        value {
          codename
        }
      }
      vertical_padding__padding_top {
        value {
          codename
        }
      }
      first_column {
        value
        modular_content {
          system {
            id
            codename
          }
          internal {
            type
          }
          ...BlockQuote
          ...kontentMediaImage
          ...HorizontalRule
          ...FormattedRichText
          ...ComponentCTA
          ...kontentStaffMemberItem
          ...SidebarLinks
        }
        links {
          url_slug
          type
          url
          link_id
        }
        images {
          width
          url
          height
          description
        }
      }
      second_column {
        value
        modular_content {
          system {
            id
            codename
          }
          internal {
            type
          }
          ...BlockQuote
          ...kontentMediaImage
          ...HorizontalRule
          ...FormattedRichText
          ...ComponentCTA
          ...kontentStaffMemberItem
          ...GoogleMapContainer
          ...SplashEmbedContainer
          ...LinkedImage
          ...VideoPlayer
          ...IconGroup
          ...ExhibitionTimeline
          ...ExhibitionMap
          ...FAQs
        }
        links {
          url_slug
          type
          url
          link_id
        }
        images {
          width
          url
          height
          description
        }
      }
      background_color {
        ...brandColor
      }
    }
  }
`;
