import React from "react";
import * as styles from "./TwoColumnRte.module.css";
import { ComponentProps, KontentRichText } from "../../../types/index";
import { SectionWrapper } from "../../containers/SectionWrapper";
import { CustomRichTextElement } from "../../elements/CustomRichTextElement/CustomRichTextElement";

interface Props extends ComponentProps {
  firstColumn: KontentRichText;
  secondColumn: KontentRichText;
  margin: {
    top: string;
    bottom: string;
  };
  padding: {
    top: string;
    bottom: string;
  };
  background?: string;
}

export const TwoColumnRte: React.FC<Props> = ({
  id,
  anchor,
  hideOnMobile,
  firstColumn,
  secondColumn,
  margin,
  padding,
  background,
}) => {
  let classes = "";
  if (margin?.top) {
    classes += `margin-top--${margin.top} `;
  }
  if (margin?.bottom) {
    classes += `margin-bottom--${margin.bottom} `;
  }
  if (padding?.top) {
    classes += `padding-top--${padding.top} `;
  }
  if (padding?.bottom) {
    classes += `padding-bottom--${padding.bottom} `;
  }

  return (
    <SectionWrapper
      id={id}
      anchor={anchor}
      hideOnMobile={hideOnMobile}
      background={background}
    >
      <div className={`${styles.twocolumnrte} ${classes}`}>
        <div
          className={styles.secondColumn}
          data-kontent-element-codename="second_column"
        >
          <CustomRichTextElement content={secondColumn} />
        </div>
        <div
          className={styles.firstColumn}
          data-kontent-element-codename="first_column"
        >
          <CustomRichTextElement content={firstColumn} />
        </div>
      </div>
    </SectionWrapper>
  );
};
